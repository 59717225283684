import * as React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx, Styled } from "theme-ui";
import { Link } from "gatsby";
import timesLogo from "src/images/times.svg";
// import classes from "./MainHeader.module.scss";
import whiteHeaderLogo from "src/images/logo-black-small.svg";

const width = ["90%", "90%", "90%", "90%", "50%"];

const styles = {
  toggleWrapper: {
    height: [39, 58],
    width: [39, 58],
    margin: [3, 0],
  },
  toggleNavButton: {
    borderColor: "black",
    borderStyle: "solid",
    borderRadius: "50%",
    borderWidth: "m",
    height: [39, 58],
    width: [39, 58],
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  toggleLine: {
    borderTop: "1px solid",
    borderColor: "black",
    borderWidth: "m",
    width: "calc(100% - 12px)",
    height: "1px",
    margin: "3px auto",
  },
};

interface Props {
  closeLink: string;
}

export const navigation: React.SFC<Props> = (props) => {
  return (
    <header
      sx={{
        width,
        margin: ["0 auto"],
        height: ["unset", "10vh"],
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        zIndex: 100,
      }}
    >
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Link to="/">
          <Styled.img src={whiteHeaderLogo} />
        </Link>
        <div sx={styles.toggleWrapper}>
          <Link to={props.closeLink}>
            {/*
             // @ts-ignore */}
            <div sx={styles.toggleNavButton}>
              <Styled.img src={timesLogo} />
            </div>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default navigation;
