import * as React from "react";
import ListItem from "./ListItem";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx, Styled } from "theme-ui";

const width = ["90%", "90%", "90%", "90%", "50%"];

export const NavigationList: React.SFC = (props) => {
  return (
    <div
      sx={{
        width,
        marginTop: "10vh",
        ml: [0, "15vw"],
        marginX: ["5vw", "auto"],
        zIndex: 100,
      }}
    >
      <ol
        sx={{
          margin: 0,
          counterReset: "li",
          display: "grid",
          gridRowGap: [3],
        }}
      >
        <ListItem link="/#services" i={1}>
          Что делаем
        </ListItem>
        <ListItem link="/#cases" i={1}>
          Кейсы
        </ListItem>
        <ListItem link="/aboutUs" i={1}>
          О нас{" "}
        </ListItem>
        <ListItem link="/web" i={1}>
          Веб
        </ListItem>
        <ListItem link="/smm" i={1}>
          Smm
        </ListItem>
        <ListItem link="/content" i={1}>
          Контент
        </ListItem>
      </ol>
    </div>
  );
};

export default NavigationList;
