import * as React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx, Styled } from "theme-ui";
import { PageProps } from "gatsby";
import Seo from "src/components/Seo";
import NavigationHeader from "src/routes/navigation/NavigationHeader";
import NavigationList from "src/routes/navigation/NavigationList";
import mIcon from "src/images/letters/m-black-stroke.svg";
import eIcon from "src/images/letters/e-black-stroke.svg";
import Footer from "src/components/Footer";

const navigation: React.SFC<PageProps> = (props) => {
  // @ts-ignore
  const closeTo: string = props.location?.state?.closeTo || "/";

  return (
    <div
      sx={{
        position: "relative",
        overflowX: "hidden",
        width: "100vw",
        height: "100vh",
        backgroundColor: "secondary",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Seo pageTitle="Навигация" />
      <NavigationHeader closeLink={closeTo} />
      <NavigationList />
      <Footer />
      <div
        sx={{
          position: "absolute",
          right: "-8%",
          bottom: "20%",
          height: ["unset", 200],
          zIndex: 0,
        }}
      >
        <img
          sx={{
            height: "100%",
          }}
          src={mIcon}
        />
        <img
          sx={{
            height: "100%",
          }}
          src={eIcon}
        />
      </div>
    </div>
  );
};

export default navigation;
