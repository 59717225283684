import React from "react";

// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx, Styled } from "theme-ui";

interface Props {
  color?: string;
  styles?: object;
}

const Footer: React.SFC<Props> = (props) => {
  const linkStyles = {
    color: "primary",
    textDecoration: "none",
    mr: "5vw",
  };

  return (
    <footer
      sx={{
        margin: "auto",
        // marginBottom: "5vh",
        ml: ["5vw", "20vw"],
        textAlign: ["center", "unset"],
      }}
    >
      <a sx={linkStyles} href="mailto:info@shakeme.agency">
        info@shakeme.agency
      </a>
      <a sx={linkStyles} href="tel:+79959113449">
        +7 (995) 911-34-49
      </a>
    </footer>
  );
};

export default Footer;
