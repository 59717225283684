import * as React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx, Styled } from "theme-ui";
import { Link } from "gatsby";
import blkArrow from "src/images/little-black-arrow.svg";

interface Props {
  link: string;
  i: number;
}

const ListItem: React.SFC<Props> = (props) => {
  const hover = {
    transition: "0.3s",
    WebkitTextStrokeWidth: 1.2,
    WebkitTextStrokeColor: "black",
    color: "transparent",
  };

  const liStyles = {
    width: "fit-content",
    position: "relative",
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    ":hover img": {
      position: "static",
      opacity: "1",
      visibility: "visible",
      transition: "visibility 0s, opacity 1s linear",
    },
    ":hover a": hover,
    ":hover:before": {
      color: "white",
    },
    ":before": {
      mr: 48,
      content: 'counter(li, decimal-leading-zero) " "',
      counterIncrement: "li",
      color: "black",
      fontSize: 24,
      fontWeight: 100,
      fontFamily: ["Open Sans", "sans-serif"],
      textAlign: "center",
    },
  };
  return (
    // @ts-ignore
    <li sx={liStyles}>
      <Styled.img
        sx={{
          mr: [2],
          position: "absolute",
          visibility: "hidden",
        }}
        src={blkArrow}
      />
      <h4
        sx={{
          textTransform: "uppercase",
        }}
      >
        <Link
          to={props.link}
          // @ts-ignore
          sx={{
            textDecoration: "none",
            color: "black",
            fontSize: 48,
            ...{ ":hover": hover },
          }}
        >
          {props.children}
        </Link>
      </h4>
    </li>
  );
};
export default ListItem;
